
import Vue from 'vue'
import Default from '@/layouts/Default.vue'
import Dashboard from '@/layouts/Dashboard.vue'
import Empty from '@/layouts/Empty.vue'
import Fill from '@/layouts/Fill.vue'
import Login from '@/layouts/Login.vue'

const defaultLayout = 'Default'

export default Vue.extend({
  name: 'AppLayout',
  components: {
    Default,
    Dashboard,
    Empty,
    Fill,
    Login
  },
  computed: {
    layout () {
      const layout = this.$route.meta?.layout || defaultLayout
      return layout
    }
  }
})
