
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Menu from './shared/Menu.vue'
interface YearOption {
  value: string
  text: string
}
export default Vue.extend({
  name: 'Header',
  data: () => ({
    year: ''
  }),
  computed: {
    ...mapGetters({
      authority: 'user/authority',
      user: 'user/userData',
      token: 'user/token',
      yearOptions: 'options/yearOptions'
    }),
    isLogin (): boolean {
      return this.token
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      logout: 'user/logout',
      setDashboardSelectedTime: 'page/setDashboardSelectedTime'
    }),
    fetchYear (item: YearOption) {
      console.log('fetchYear')
      this.year = item.text
      this.setDashboardSelectedTime(item.value)
    },
    userLogout () {
      this.logout()
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  },
  mounted () {
    this.year = this.yearOptions[0].text
  },
  components: {
    'geo-menu': Menu
  }
})
