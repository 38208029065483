
import Vue from 'vue'
import Header from '@/components/layouts/DashboardHeader.vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'Dashboard',
  components: {
    Header
    // Footer
  },
  computed: {
    ...mapGetters({
      loading: 'page/loading'
    })
  }
})
