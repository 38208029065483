import Vue from 'vue'
import App from './App.vue'
import store from './store'

import AppLayout from '@/layouts/AppLayout.vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'

// 套件
import './vendor'

// ECharts
import ECharts from './vendor/chart'
import Echart from '@/components/Echart.vue'

// openlayers
import 'ol/ol.css'

// slick
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.min.js'

// my component
import CSwitch from '@/components/Switch.vue'
Vue.component('v-chart', ECharts)
Vue.component('Echart', Echart)
Vue.component('CSwitch', CSwitch)

// Layout
Vue.component('AppLayout', AppLayout)

Vue.use(Vuetify)

Vue.config.productionTip = false

const init = async () => {
  const module = await import('./router')
  const router = await module.default
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')
}

init()
