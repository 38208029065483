
import Vue from 'vue'
import Header from '@/components/layouts/Header.vue'
import InfoText from '@/components/layouts/InfoText.vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'Default',
  components: {
    Header,
    InfoText
  },
  computed: {
    ...mapGetters({
      loading: 'page/loading'
    })
  }
})
