// axios
import jwtDecode from 'jwt-decode'
import axios, { AxiosError, AxiosResponse } from 'axios'

const baseUrl: string =
    process.env.NODE_ENV === 'development'
      ? '/apiUrl/moc/api'
      : process.env.VUE_APP_API_URL + '/api'
let renewTime
axios.defaults.timeout = 500000
axios.interceptors.request.use((config) => {
  const nowTime = new Date().getTime()
  const userData = JSON.parse(localStorage.getItem('userData') || '{}')
  if (!userData.token || (renewTime && (renewTime + 300000 > nowTime))) return config;
  axios.get(baseUrl + '/login/updateToken').then((ret) => {
    if (ret.status === 200) {
      const decoded: any = jwtDecode(ret.data.token) || {}
      userData.token = ret.data.token
      userData.exp = decoded.exp
      axios.defaults.headers.common.Authorization = 'Bearer ' + userData.token
      localStorage.setItem('userData', JSON.stringify(userData))
    }
  })
  renewTime = new Date().getTime()
  return config;
})
// Add a response interceptor
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    console.error(error)
    if (!error.response) {
      console.log('error')
    }
    if (error.response && error.response.status === 500) {
      return Promise.reject(error)
    }
    if (error.response && error.response.status === 401) {
      window.location.reload()
      return Promise.reject(error)
    }
    if (
      error.response &&
      // @ts-ignore
      (Number(error.response.data.code) === 10010 ||
        // @ts-ignore
        Number(error.response.data.code) === 10020 ||
        // @ts-ignore
        Number(error.response.data.code) === 10030)
    ) {
      throw error
    }
    return Promise.reject(error)
  }
)

export default axios
