import { GetterTree, MutationTree, ActionTree } from 'vuex'
import OptionsState from '../types/chart'
import RootState from '../RootTypes'

const state: OptionsState = {
  chart: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '4%',
      right: '4%',
      bottom: '5%',
      top: '12%',
      containLabel: true
    },
    legend: {
      show: true,
      right: 15
    },
    xAxis: [
      {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        type: 'bar',
        barWidth: '60%',
        data: [10, 52, 200, 334, 390, 330, 220]
      }
    ]
  },
  mapStyle: {
    type: 'map',
    roam: true,
    aspectScale: 1
  }
}

const mutations: MutationTree<OptionsState> = {
}

const actions: ActionTree<OptionsState, RootState> = {
}

const getters: GetterTree<OptionsState, RootState> = {
  option: state => state.chart || null,
  mapStyle: state => state.mapStyle
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
