
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { GetterTree, MutationTree, ActionTree } from 'vuex'
// https://shangdeyou.github.io/2021/06/24/vuex-with-typescript-in-vue2/
import UserState from '../types/user'
import RootState from '../RootTypes'
import User from '@/api/user'
import axios from '@/vendor/axios'
import jwtDecode from 'jwt-decode'

const state: UserState = {
  userData: null
}

const mutations: MutationTree<UserState> = {
  SET_USERDATA (state, data) {
    state.userData = data
  }
  // SET_TOKEN (state, token: string) {
  //   state.token = token
  // }
}

const actions: ActionTree<UserState, RootState> = {
  async login ({ dispatch }, userInfo) {
    return new Promise(async (resolve, reject) => {
      User.login(userInfo)
        .then(({ data }) => {
          const token = data.token
          const decoded: any = jwtDecode(data.token) || {}
          const arena = `${decoded.arena || ''}`.replaceAll("'", '').split(',')
          const role: number = Number(decoded.role) || 5
          enum roleTypeCodes {
            '系統管理員' = 1,
            '主管機關',
            '經營機構',
            '一般使用者',
            '一般民眾'
          }
          const userData = {
            userName: decoded.name,
            roleName: roleTypeCodes[role],
            // authority: role,
            arena,
            token,
            email: decoded.email,
            exp: decoded.exp,
          }
          dispatch('storeData', { userData })
          resolve(true)
        })
        .catch(error => {
          console.error(error)
          reject(error.response)
        })
    })
  },
  storeData ({ commit }, { userData }) {
    commit('SET_USERDATA', userData)
    axios.defaults.headers.common.Authorization = 'Bearer ' + userData.token
    localStorage.setItem('userData', JSON.stringify(userData))
  },
  logout  ({ commit }) {
    console.log('logout')
    User.addLogoutRecord();
    commit('SET_USERDATA', null)
    axios.defaults.headers.common.Authorization = ''
    localStorage.setItem('userData', '{}')
  }
}

const getters: GetterTree<UserState, RootState> = {
  userData: state => state.userData || {},
  userArea: state => {
    return state.userData?.arena || []
  },
  token: state => {
    return state.userData?.token || ''
  },
  authority: state => {
    return state.userData?.authority || 5
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
