<template>
  <div v-if="info[path]" class="mt-2 caption">
    功能說明: {{ info[path] }}
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'InfoText',
  mixins: [],
  components: {
  },
  props: {
  },
  data () {
    return {
      info: {
        // 藝文活動特定年度場次歷史統計概況(A1)
        '/analytic/realtime/history': '可查詢任一年度內全國曾經舉辦過的藝文活動，藉由相關的統計圖表可呈現依據舉辦所在之區域或縣市之舉辦活動總數目及場次總數目、並且可查詢該年度內各個月統計量以及可查詢至特定月份的每日統計數據相關圖表，除此之外亦可依據活動類別、場館類別展示全國藝文活動相關分佈統計圖表。',
        // 查詢藝文活動未來6個月預定舉辦場次統計(A2)
        '/analytic/realtime/future': '可查詢當年度未來6個月內全國預定舉辦的藝文活動，藉由相關的統計圖表可呈現依據活動預定舉辦所在之區域或縣市之舉辦活動總數目及場次總數目、並且可查詢未來6個月內之相關統計量以及可查詢至特定月份的每日統計數據，除此之外亦可依據活動類別、場館類別展示未來6個月內預定舉辦之全國藝文活動相關分佈統計圖表。',
        // 特定場所特定年度場次歷史統計概況(A3)
        '/analytic/realtime/history-place': '可查詢全國特定場所任一年度內所舉辦的藝文活動統計資訊，包括該年度內各個月統計量以及可查詢至特定月份的每日統計數據相關圖表，除此之外亦可就特定場所依據活動類別展示相關的藝文活動統計圖表。',
        // 特定場所未來6個月預定舉辦場次統計(A4)
        '/analytic/realtime/future-place': '可查詢全國特定場所當年度未來6個月內預定舉辦的藝文活動統計資訊，包括未來6個月內之各個月統計量以及可查詢至特定月份的每日統計數據相關圖表，除此之外亦可就特定場所依據活動類別展示相關的藝文活動統計圖表。',

        // 藝文活動基本分析(B1)
        '/analytic/reality/basic': '區域縣市活動類別場次統計分析與館所場地別活動類別場次統計分析。查詢分析成果可藉由柱狀圖或區域圖形呈現特定時期內全國個別區域依活動類別的活動或場次數量分佈，以及依據全國館所場地場所來呈現所舉辦的活動或場次數量在活動類別上的分佈情形。',
        // 藝文活動參與分析(B2)
        '/analytic/reality/behavior': '區域縣市別活動類別出席人數統計分析，以及經營機構自身的相關藝文活動館所場地別活動類別出席人數統計分析。查詢分析成果可藉由柱狀圖或區域圖形，呈現特定時期內特定區域(或依縣市別)活動出席人數在活動類別上的統計分佈，以及依據全國館所場地場所來呈現所舉辦的活動出席人數在活動類別上的分佈情形。',
        // 藝文活動比較分析(B3)
        '/analytic/reality/compare': '藝文活動參與分析功能包括針對區域或是以縣市為比較分析對象，分析內容則以藝文活動舉辦的活動種類與場所種類之間的比較分析為主。查詢分析成果可藉由跨年度的折線圖，呈現特定時期內特定區域(或依縣市別)的活動(或場次)舉辦數量在活動類別上的統計差異比較結果。',

        // 全資料智慧查詢(D1)
        '/analytic/search/intelligence': '可以協助使用者在所關注的業務範圍內，依據實際需求以半自動化方式在大量活動基本資料中，提供使用者以自然語言方式設定相關藝文活動搜尋條件，並在全部活動資料內搜尋擴大所要分析的目標活動以提取特定主題項目的活動，據以建立具有統計分析意義的資料集。',
        // 小資料診斷分析(D2)
        '/analytic/search/analysis': '可依據全資料智慧查詢查詢所得到的特定主題活動資料集，進一步分析活動主題內容關鍵字詞，或是另外配合本系統內其他針對特定藝文活動資料集的各種統計分析功能，則可深入分析各活動觀眾出席參與指標的相關統計分析意義。'
      }
    }
  },
  computed: {
    ...mapGetters({
    }),
    path () {
      return this.$route.path
    }
  },
  watch: {
  },
  mounted () {
    //
  },
  methods: {
    ...mapActions({
    })
  }
})
</script>
