/* eslint-disable  @typescript-eslint/no-explicit-any */
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import OptionsState from '../types/options'
import RootState from '../RootTypes'
import infoList from '@/api/infoList'
import member from '@/api/member'

const state: OptionsState = {
  startYear: 2019,
  yearOptions: [],
  categoryOptions: null,
  roleList: null,
  orgList: null,
  fieldArenaOptions: null,
  regionArenaOptions: null
}

const mutations: MutationTree<OptionsState> = {
  SET_CATEGORY (state, data) {
    state.categoryOptions = data
  },
  SET_ROLELIST (state, data) {
    state.roleList = data
  },
  SET_ORGLIST (state, data) {
    state.orgList = data
  },
  SET_FIELDARENA (state, data) {
    state.fieldArenaOptions = data
  },
  SET_REGIONARENA (state, data) {
    state.regionArenaOptions = data
  }
}

const actions: ActionTree<OptionsState, RootState> = {
  async getCategory ({ commit }) {
    try {
      const { data } = await infoList.Category()
      const opt = (data.category || []).map((d: string) => ({
        text: d,
        value: d
      }))
      commit('SET_CATEGORY', opt || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getRoleList ({ commit }) {
    try {
      const { data } = await infoList.RoleList()
      const opt = (data || []).map((d: any) => ({
        text: d.roleName,
        value: (d.roleNum).toString()
      }))
      commit('SET_ROLELIST', opt || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getOrgList ({ commit }) {
    try {
      const { data } = await member.OrgList()
      const opt = (data.data || []).map((d: any) => ({
        text: d.name,
        value: d.code
      }))
      commit('SET_ORGLIST', opt || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getFieldArenaOptions ({ commit }) {
    try {
      const { data } = await infoList.FieldArena()
      commit('SET_FIELDARENA', data || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getRegionArenaOptions ({ commit }) {
    try {
      const { data } = await infoList.RegionArena()
      commit('SET_REGIONARENA', data || [])
    } catch (error) {
      console.error(error)
    }
  }
}

const getters: GetterTree<OptionsState, RootState> = {
  startYear: state => state.startYear,
  yearOptions: state => {
    const yearOptions = [] as Record<string, string>[]
    for (let year = Number(moment().format('YYYY')); year >= state.startYear; year--) {
      const startOfYear = moment(year, 'YYYY').startOf('year')
      const endOfYear = moment(year, 'YYYY').endOf('year')
      const actualEndDate = moment().isAfter(endOfYear) ? endOfYear : moment()
      yearOptions.push({
        text: `${year - 1911} 年度`,
        value: `${startOfYear.format('YYYY/MM/DD')},${actualEndDate.format('YYYY/MM/DD')}`
      })
    }
    return yearOptions
  },
  categoryOptions: state => state.categoryOptions,
  roleList: state => state.roleList,
  orgList: state => state.orgList,
  fieldArenaOptions: state => state.fieldArenaOptions,
  regionArenaOptions: state => state.regionArenaOptions
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
