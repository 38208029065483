
import Vue from 'vue'
export default Vue.extend({
  name: 'CSwitch',
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    data () {
      this.$emit('input', this.data)
    }
  },
  data () {
    return {
      data: false
    }
  },
  mounted () {
    this.data = this.value
  }
})
