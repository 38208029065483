import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { PageState, SelectOptions } from '../types/page'
import RootState from '../RootTypes'

const state: PageState = {
  loading: false,
  conditionsLoading: false,
  conditionsDisable: false,
  conditions: null,
  showDialogAgain: true,
  dashboardSelectedTime: null,
  menus: [],
  functionMenus: [],
  menuOptions: [
    {
      label: '藝文活動數據即時展示',
      link: '/analytic/realtime',
      value: 'eventOptions',
      role: [1, 2, 3]
    },
    {
      label: '真實參與統計分析',
      link: '/analytic/reality',
      value: 'realityOptions',
      role: [1, 2, 3]
    },
    {
      label: '客群輪廓推估分析',
      link: '/analytic/profile',
      value: 'profileOptions',
      role: [1, 2, 3]
    },
    {
      label: '進階查詢分析',
      link: '/analytic/search',
      value: 'searchOptions',
      role: [1, 2]
    }
  ],
  eventOptions: [
    {
      label: '全國藝文活動儀表板',
      to: '/',
      menuId: '',
      role: [1, 2, 3]
    },
    {
      label: '藝文活動**特定年度場次歷史**統計概況',
      value: 'history',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591010',
      role: [1, 2, 3]
    },
    {
      label: '藝文活動**未來 6 個月預定舉辦**場次統計',
      value: 'future',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591020',
      role: [1, 2, 3]
    },
    {
      label: '**特定場所**特定年度場次歷史統計概況',
      value: 'history-place',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591030',
      role: [1, 2]
    },
    {
      label: '**特定場所**未來 6 個月預定舉辦場次統計',
      value: 'future-place',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591040',
      role: [1, 2]
    },
    {
      label: '**所轄場所**歷史統計概況',
      value: 'history-event',
      menuId: '',
      role: [3]
    },
    {
      label: '**所轄場所**未來 6 個月預定舉辦場次統計',
      value: 'future-event',
      menuId: '',
      role: [3]
    }
  ],
  realityOptions: [
    {
      label: '藝文活動基本分析',
      value: 'basic',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591110',
      role: [1, 2, 3],
      children: [
        {
          label: '藝文活動基本分析',
          value: 'index',
          role: [3]
        },
        {
          label: '區域活動類別場次',
          value: 'region',
          role: [1, 2]
        },
        {
          label: '館所場地活動類別場次',
          value: 'venue',
          role: [1, 2]
        }
      ]
    },
    {
      label: '藝文活動參與分析',
      value: 'behavior',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591120',
      role: [1, 2, 3],
      children: [
        {
          label: '藝文活動參與分析',
          value: 'index',
          role: [3]
        },
        {
          label: '區域活動類別出席人數',
          value: 'region',
          role: [1, 2]
        },
        {
          label: '館所場地活動類別出席人數',
          value: 'venue',
          role: [1, 2]
        }
      ]
    },
    {
      label: '藝文活動比較分析',
      value: 'compare',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591130',
      role: [1, 2],
      children: [
        {
          label: '區域比較分析',
          value: 'compareRegion',
          role: [1, 2]
        },
        {
          label: '縣市比較分析',
          value: 'compareCounty',
          role: [1, 2]
        }
      ]
    }
  ],
  profileOptions: [
    {
      label: '依活動類別差異比較',
      value: 'activity',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591210',
      role: [1, 2, 3]
    },
    {
      label: '依場館場地差異比較',
      value: 'place',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591220',
      role: [1, 2],
      children: [
        {
          label: '依館所場地分類別',
          value: 'arena'
        },
        {
          label: '依個別館所場地',
          value: 'venue'
        }
      ]
    },
    {
      label: '依區域縣市差異比較',
      value: 'area',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591230',
      role: [1, 2],
      children: [
        {
          label: '依北中南東區域別',
          value: 'region'
        },
        {
          label: '依縣市別',
          value: 'county'
        }
      ]
    },
    {
      label: '依場次時段差異比較',
      value: 'time',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591240',
      role: [1, 2, 3],
      children: [
        {
          label: '依早中晚時段類別',
          value: 'during'
        },
        {
          label: '依假日平日類別',
          value: 'holiday'
        }
      ]
    },
    {
      label: '跨領域與跨場域的活動場次比較',
      value: 'field',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591250',
      role: [1, 2, 3],
      children: [
        {
          label: '觀眾性別與年齡層交叉分析',
          value: 'audience'
        },
        {
          label: '觀眾跨區移動分析',
          value: 'moving'
        }
      ]
    }
  ],
  searchOptions: [
    {
      label: '全資料智慧查詢',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591310',
      value: 'intelligence',
      role: [1, 2]
    },
    {
      label: '小資料診斷分析',
      menuId: '99dfeef1-ffe4-402f-b60c-b2f72b591320',
      value: 'analysis',
      role: [1, 2]
    }

  ]
}

const mutations: MutationTree<PageState> = {
  SET_LOADING: (state, status) => {
    state.loading = status
  },
  SET_CONDITION: (state, conditions) => {
    state.conditions = conditions
  },
  SET_SHOWDIALOGAGAIN: (state, status) => {
    state.showDialogAgain = status
  },
  SET_DASHBOARDSELECTEDTIME: (state, status) => {
    state.dashboardSelectedTime = status
  },
  SET_MENU: (state, menu) => {
    state.menus = menu
  },
  SET_FUNCTION_MENU: (state, menu) => {
    state.functionMenus = menu
  }, 
}

const actions: ActionTree<PageState, RootState> = {
  setLoading ({ commit }, status) {
    commit('SET_LOADING', status)
  },
  setConditions ({ commit }, conditions) {
    commit('SET_CONDITION', conditions)
  },
  setShowDialogAgain ({ commit }, status) {
    commit('SET_SHOWDIALOGAGAIN', status)
  },
  setDashboardSelectedTime ({ commit }, status) {
    commit('SET_DASHBOARDSELECTEDTIME', status)
  },
  setMenu({ commit }, menu) { 
    commit('SET_MENU', menu)
  },
  setFunctionMenu({ commit }, menu) { 
    commit('SET_FUNCTION_MENU', menu)
  },
}

const getters: GetterTree<PageState, RootState> = {
  loading: state => state.loading,
  conditions: state => state.conditions || {},
  showDialogAgain: state => state.showDialogAgain,
  dashboardSelectedTime: state => state.dashboardSelectedTime,
  menuOptions: (state, getters, rootState, rootGetters) => {
    if (rootGetters['user/token']) {
      return state.menuOptions.filter(d => {
        return d.role?.includes(rootGetters['user/authority'])
      })
    }
    return []
  },
  profileOptions: (state, getters, rootState, rootGetters) => {
    if (rootGetters['user/token']) {
      if (!state.menus.length) return state.profileOptions;
      const upperId = '99dfeef1-ffe4-402f-b60c-b2f72b591200';
      const currentMenu = state.menus.find(item => item.menuId === upperId);
      const subMenu = currentMenu.subMenuVms.map(item => item.menuId);
      return state.profileOptions.filter(d => subMenu.includes(d.menuId));
    }
    return []
  },
  eventOptions: (state, getters, rootState, rootGetters) => {
    if (rootGetters['user/token']) {
      if (!state.menus.length) return state.eventOptions;
      const upperId = '99dfeef1-ffe4-402f-b60c-b2f72b591000';
      const currentMenu = state.menus.find(item => item.menuId === upperId);
      const subMenu = currentMenu.subMenuVms.map(item => item.menuId);
      return state.eventOptions.filter(d => subMenu.includes(d.menuId));
    }
    return []
  },
  realityOptions: (state, getters, rootState, rootGetters) => {
    if (rootGetters['user/token']) {
      if (!state.menus.length) return state.realityOptions;
      const upperId = '99dfeef1-ffe4-402f-b60c-b2f72b591100';
      const currentMenu = state.menus.find(item => item.menuId === upperId);
      const subMenu = currentMenu.subMenuVms.map(item => item.menuId);
      return state.realityOptions.filter(d => subMenu.includes(d.menuId));
    }
    return []
  },
  searchOptions: (state, getters, rootState, rootGetters) => {
    if (rootGetters['user/token']) {
      if (!state.menus.length) return state.searchOptions;
      const upperId = '99dfeef1-ffe4-402f-b60c-b2f72b591300';
      const currentMenu = state.menus.find(item => item.menuId === upperId);
      const subMenu = currentMenu.subMenuVms.map(item => item.menuId);
      return state.searchOptions.filter(d => subMenu.includes(d.menuId));
    }
    return []
  },
  menus: state => state.menus,
  functionMenus: state => state.functionMenus,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
