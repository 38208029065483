
import Vue from 'vue'
// import VChart, { THEME_KEY } from 'vue-echarts'
import ECharts from '@/vendor/chart'

interface DataZoom
 {
    type: 'datazoom',
    start: number
    end: number
    startValue?: number
    endValue?: number
}
export default Vue.extend({
  name: 'Echart',
  components: {
    VChart: ECharts
  },
  props: {
    theme: {
      type: String,
      default: 'chartTheme'
    },
    option: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    noData: {
      type: Boolean,
      default: () => false
    },
    noDataText: {
      type: String,
      default: () => '無資料'
    }
  },
  watch: {},

  data: () => ({
  }),

  computed: {},

  methods: {
    makeChart () {
      console.log('makeChart')
    },
    datazoom (evn: DataZoom) {
      this.$emit('datazoom', evn)
    },
    mouseover (evn: string[]) {
      this.$emit('mouseover', evn, this.$refs.chart)
    },
    mouseout (evn: string[]) {
      this.$emit('mouseout', evn, this.$refs.chart)
    },
    highlight (evn: string[]) {
      this.$emit('highlight', evn, this.$refs.chart)
    },
    downplay (evn: string[]) {
      this.$emit('downplay', evn, this.$refs.chart)
    },
    click (evn: string[]) {
      this.$emit('click', evn, this.$refs.chart)
    }
  }
})
