/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios from '@/vendor/axios'
import { AxiosResponse } from 'axios'

interface Params {
  id?: string | number
  arena: string | null
  email: string
  name: string
  password: string
  role: string
}

interface PasswordParams {
  qooword: string,
  newQooWord: string,
  newQooWordConfirm: string,
}

interface UserArenaParams {
  userId: string
  arenaList: string[],
}

interface PageParams {
  CurrentPage: number
  PageSize: number
  Keyword?: string
  Sorting?: string
  SortingDesc?: boolean
}

interface UserSpotAuthParams extends PageParams {
  UserId: string
}

interface UserSpotAuthEditParams {
  userSpotAuthorityId: string
  spotId: number
  userId: string
  startDate: string
  endDate: string
  isEverLasting: boolean
  spotName: string
}

class API {
  static baseUrl: string =
    process.env.NODE_ENV === 'development'
      ? '/apiUrl/moc/member'
      : process.env.VUE_APP_API_URL + '/member'

  static list (): Promise<AxiosResponse<any>> {
    return axios.get(this.baseUrl + '/findAllMember')
  }

  static register (params: Params): Promise<AxiosResponse<any>> {
    return axios.post(this.baseUrl + '/register', params)
  }

  static update (params: Params): Promise<AxiosResponse<any>> {
    return axios.post(this.baseUrl + '/update', params)
  }

  static delete (id: string | number): Promise<AxiosResponse<any>> {
    return axios.post(this.baseUrl + '/delete', {
      id
    })
  }
  static OrgList (): Promise<any> {
    return axios.get(this.baseUrl + '/getOrganizations')
  }
  static updatePassword (params: PasswordParams): Promise<any> {
    return axios.post(this.baseUrl + '/changeSelfQooWord', params)
  }
  static GetUserArena (params: string): Promise<any> {
    return axios.get(`${this.baseUrl}/GetUserArena/${params}`)
  }
  static GetArenaList (): Promise<any> {
    return axios.get(this.baseUrl + '/GetArenaList')
  }
  static UpdateUserArena (params: UserArenaParams): Promise<any> {
    return axios.post(this.baseUrl + '/UpdateUserArena', params)
  }
  static UserSpotAuthQuery (params: UserSpotAuthParams): Promise<any> {
    return axios.get(`${this.baseUrl}/UserSpotAuthQuery`, {params})
  }
  static DeleteUserSpotAuth (params: string): Promise<any> {
    return axios.post(this.baseUrl + '/DeleteUserSpotAuth/' + params)
  }
  static GetUserSpotAuthDetail (params: string): Promise<any> {
    return axios.get(`${this.baseUrl}/GetUserSpotAuthDetail/${params}`)
  }
  static UserSpotAuthEdit (params: UserSpotAuthEditParams): Promise<any> {
    return axios.post(this.baseUrl + '/UserSpotAuthEdit', params)
  }
  static GetUserSpotAuthApplies (params: PageParams): Promise<any> {
    return axios.get(`${this.baseUrl}/GetUserSpotAuthApplies`, { params })
  }
  static SwitchUserSpotAuthApply (params: string): Promise<any> {
    return axios.post(`${this.baseUrl}/SwitchUserSpotAuthApply/${params}`)
  }
  static ExportAllMember (): Promise<any> {
    return axios.get(
      `${this.baseUrl}/ExportAllMember`,
      { responseType: 'blob' }
    )
  }

}

export default API
