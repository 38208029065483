/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios from '@/vendor/axios'

class API {
  static baseUrl: string = process.env.NODE_ENV === 'development'
    ? '/apiUrl/moc/api/Common'
    : process.env.VUE_APP_API_URL + '/api/Common'

  static Role (): Promise<any> {
    return axios.get(this.baseUrl + '/Role')
  }
  static GetMenu (): Promise<any> {
    return axios.get(this.baseUrl + '/GetMenu')
  }
  static GetAllMenus (): Promise<any> {
    return axios.get(this.baseUrl + '/GetAllMenus')
  }
  static GetAllSpot (): Promise<any> {
    return axios.get(this.baseUrl + '/GetAllSpot')
  }
}

export default API
