
import Vue from 'vue'
import Menu from './shared/Menu.vue'
import { mapActions, mapGetters } from 'vuex'
import Member from '@/api/member'

interface Params {
  password: string,
  newPassword: string,
}

export default Vue.extend({
  name: 'Header',
  data: () => ({
    dialog: false,
    passwordRules: [
      (v: string) => !!v || 'Password is required',
      (v: string) => (v || '').length >= 12 || '密碼至少必須包含 12 個字元',
      (v: string) => {
        const pattern = /[0-9]+/
        return pattern.test(v) || '必須包含數字'
      },
      (v: string) => {
        const pattern = /[a-z]+/
        return pattern.test(v) || '必須包含小寫英文'
      },
      (v: string) => {
        const pattern = /[A-Z]+/
        return pattern.test(v) || '必須包含大寫英文'
      },
      (v: string) => {
        const pattern = /[\\+\\=\\[\]~!@#$%^&*_-`|(){}:;"'<>,.?/]+/
        return pattern.test(v) || '必須包非英數字元：~!@#$%^&*_-+=`|\\(){}[]:;"\'<>,.?/'
      }
    ],
    valid: false as boolean,
    passwordCheck: '' as string,
    params: {
      password: '',
      newPassword: '',
    } as Params,
    showToggle: {
      password: true as boolean,
      passwordCheck: true as boolean
    },
    errorMsg: '' as string,
    successMsg: '' as string,
  }),
  computed: {
    ...mapGetters({
      authority: 'user/authority',
      user: 'user/userData',
      token: 'user/token'
    }),
    isLogin (): boolean {
      return this.token
    },
    isSystem (): boolean {
      // TODO: premission
      return this.isLogin && this.$route.fullPath.indexOf('/system') > -1
    },
    authorityManage (): boolean {
      return this.authority <= 1
    },
    passwordCheckRules (): Array<unknown> {
      return [
        (v: string) => !!v || 'Password is required',
        (v: string) => v === this.params.newPassword || '兩次輸入的新密碼不相同，請重新輸入',
      ]
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      logout: 'user/logout'
    }),
    userLogout () {
      this.logout()
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    },
    toggleDialog () {
      this.dialog = !this.dialog;
    },
    async changePassword () {
      if (this.errorMsg) {
        this.errorMsg = '';
      }
      if (this.successMsg) {
        this.successMsg = '';
      }
      try {
        if (this.valid) {
          const params = {
            qooword: this.params.password,
            newQooWord: this.params.newPassword,
            newQooWordConfirm: this.passwordCheck,
          }
          const resp = await Member.updatePassword(params);
          if (resp.data.success) {
            this.successMsg = "修改成功";
          } else {
            throw resp.data.message;
          }
        } else {
          throw '驗證失敗';
        }
      } catch (error) {
        console.warn(error);
        this.errorMsg = typeof error === 'string' ? error : '發生錯誤';
      }
    }
  },
  components: {
    'geo-menu': Menu
  }
})
