/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios from '@/vendor/axios'

interface Params {
  username: string
  password: string
}

interface ChangePasswordParams {
  userName: string
  qooword: string
  newQooWord: string
  newQooWordConfirm: string
}

interface ResetPasswordParams {
  enforceId: string
  newQoo: string
  confirmQoo: string
}

class API {
  static baseUrl: string =
    process.env.NODE_ENV === 'development'
      ? '/apiUrl/moc/api'
      : process.env.VUE_APP_API_URL + '/api'

  static login (params: Params): Promise<any> {
    return axios.post(this.baseUrl + '/login', params)
  }

  static changePassword (params: ChangePasswordParams): Promise<any> {
    return axios.post(this.baseUrl + '/login/FChangeQooWord', params)
  }

  static enforceResetPassword (params: ResetPasswordParams): Promise<any> {
    return axios.post(this.baseUrl + '/login/EnforceResetPassword', params)
  }

  static addLogoutRecord(): Promise<any>{
    return axios.post(this.baseUrl + '/login/addLogoutRecord')
  }

  static menuShowOdPanel (): Promise<any> {
    return axios.get(this.baseUrl + '/Common/MenuShowOdPanel')
  }

  static getCaptchaSrc (): Promise<any> {
    return axios.get(this.baseUrl + '/login/Captcha')
  }
}

export default API
