import { GetterTree, MutationTree, ActionTree } from 'vuex'
import OptionsState from '../types/itriOptions'
import RootState from '../RootTypes'
import infoList from '@/api/infoList'

const state: OptionsState = {
  holidayOptions: [
    // {
    //   text: '全部日別',
    //   value: '0'
    // },
    {
      text: '假日',
      value: '1'
    },
    {
      text: '平日',
      value: '2'
    },
    {
      text: '週末',
      value: '3'
    }
  ],
  duringOptions: [
    // {
    //   text: '全部時段',
    //   value: 0
    // },
    {
      text: '早場',
      value: '1'
    },
    {
      text: '午場',
      value: '2'
    },
    {
      text: '晚場',
      value: '3'
    }
  ],
  fieldOptions: [
    {
      text: '地方文化館',
      value: '地方文化館'
    },
    {
      text: '展演空間',
      value: '展演空間'
    },
    {
      text: '藝文中心',
      value: '藝文中心'
    },
    {
      text: '創意園區',
      value: '創意園區'
    },
    {
      text: '藝術村',
      value: '藝術村'
    }
  ],
  regionArenaOptions: null,
  fieldArenaOptions: null,
  categoryOptions: null
}

const mutations: MutationTree<OptionsState> = {
  SET_CATEGORY (state, data) {
    state.categoryOptions = data
  },
  SET_REGIONARENA (state, data) {
    state.regionArenaOptions = data
  },
  SET_FIELDARENA (state, data) {
    state.fieldArenaOptions = data
  }
}

const actions: ActionTree<OptionsState, RootState> = {
  async getCategory ({ commit }) {
    try {
      const { data } = await infoList.ItriCategory()
      const opt = (data.category || []).map((d: string) => ({ text: d, value: d }))
      commit('SET_CATEGORY', opt || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getRegionArena ({ commit }) {
    try {
      const { data } = await infoList.ItriRegionArena()
      commit('SET_REGIONARENA', data || [])
    } catch (error) {
      console.error(error)
    }
  },
  async getFieldArenaOptions ({ commit }) {
    try {
      const { data } = await infoList.ItriFieldArena()
      commit('SET_FIELDARENA', data || [])
    } catch (error) {
      console.error(error)
    }
  }

}

const getters: GetterTree<OptionsState, RootState> = {
  holidayOptions: state => state.holidayOptions || [],
  duringOptions: state => state.duringOptions || [],
  duringAllOptions: state => {
    const all = {
      text: '全部時段',
      value: '0'
    }
    if (state.duringOptions) {
      return [all, ...state.duringOptions]
    }
    return [all]
  },
  fieldOptions: state => {
    const fieldArenaOptions = (state.fieldArenaOptions || []).map(({ field }) => field)
    return Array.from(new Set(fieldArenaOptions)).map(d => ({ text: d, value: d }))
  },
  regionArenaOptions: state => state.regionArenaOptions,
  fieldArenaOptions: state => state.fieldArenaOptions,
  categoryOptions: state => state.categoryOptions
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
