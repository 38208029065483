import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import zhHant from 'vuetify/lib/locale/zh-Hant'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#00c8f2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        deepSkyBlue: '#00ccf2',
        deepBlue: '#0e1e3b',
        sapphire: '#01215b',
        geyser: '#fff',
        mystic: '#e3ebeb',
        porcelain: '#f2f5f5',
        turquoise: '#49e8c2',
        perano: '#a39aff'
      }
    }
  },
  lang: {
    locales: { zhHant },
    current: 'zhHant'
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  }
})
