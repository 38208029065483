import ECharts from 'vue-echarts'
import {
  use,
  registerMap,
  registerTheme
} from 'echarts/core'
import mapData from '@/assets/map/twCounty.json'
import twRegion from '@/assets/map/twRegion.json'
import chartTheme from './chartTheme.json'
import chartThemeDark from './chartTheme-dark.json'

// import ECharts from 'vue-echarts/components/ECharts.vue'
// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,
  MapChart,
  PieChart,
  LineChart
} from 'echarts/charts'
import {
  TitleComponent,
  LegendComponent,
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
  DatasetComponent,
  TransformComponent,
  TimelineComponent,
  DataZoomComponent
} from 'echarts/components'

// import 'echarts/map'

use([
  BarChart,
  MapChart,
  PieChart,
  LineChart,
  VisualMapComponent,
  GridComponent,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  TransformComponent,
  TimelineComponent,
  DataZoomComponent
])

// registering map data
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// registerMap('twCounty', mapData, {
//   '連江縣': {       // 連江縣
//     left:  119.973046000701117,
//     top: 25.036493742382585,
//     width: 0.1
//   },
//   '金門縣': {       // 金門縣
//     left: 119.364788935627971,
//     top: 24.428372276500028,
//     width: 0.2
//   }
// })
registerMap('twCounty', mapData)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
registerMap('twRegion', twRegion)
// theme
registerTheme('chartTheme', chartTheme)
registerTheme('chartThemeDark', chartThemeDark)

export default ECharts
