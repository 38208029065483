<template>
  <v-menu
    bottom
    offset-y
    :close-on-content-click="false"
    v-model="isShow"
    v-if="functionMenus.length > 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <span class="mr-2 d-none d-sm-flex"> 功能選單 </span>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item class="d-flex d-sm-none">
        <v-list-item-title> 功能選單 </v-list-item-title>
      </v-list-item>
      <template v-for="item in functionMenus">
        <v-list-group
          :key="item.name"
          no-action
          v-if="item.subMenuVms.length"
          color="light"
        >
          <v-list-item slot="activator">
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="m in item.subMenuVms"
            :key="m.name"
            @click="go(m.url)"
            v-show="checkOdPanel(m)"
          >
            <v-list-item-title :to="m.url">{{ m.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item v-if="!item.subMenuVms.length" :key="item.name" @click="go(item.url)">
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import User from '@/api/user'
import Common from '@/api/common'

export default Vue.extend({
  name: 'Menu',
  data: () => ({
    isShow: false,
    menuShowOdPanel: false,
    menu: [],
  }),
  computed: {
    ...mapGetters({
      token: 'user/token',
      authority: 'user/authority',
      functionMenus: 'page/functionMenus',
    }),
    isLogin () {
      return this.token
    },
    isSystem () {
      return this.isLogin && this.$route.fullPath.indexOf('/system') > -1
    },
    authorityManage () {
      return this.authority <= 1
    },
  },
  async mounted() {
    if (this.isLogin) {
      const { data } = await User.menuShowOdPanel();
      this.menuShowOdPanel = data.data;      
      if (!this.functionMenus.length) this.getMenus();
    }
  },
  methods: {
    ...mapActions({
      setMenu: 'page/setMenu',
      setFunctionMenu: 'page/setFunctionMenu',
    }),
    checkOdPanel (item) {
      if (item.url === '/info/od') {
        return this.menuShowOdPanel
      }
      return true
    },
    go (url) {
      this.isShow = false
      this.$router.push(url).catch()
    },
    async getMenus() {
      try {
        const { data } = await Common.GetMenu();
        if (!data.success) throw data.message;
        this.setFunctionMenu(data.data.phase2Menu);
        this.setMenu(data.data.phase1Menu)
      } catch (e) {
        console.warn(e);
      }
    },
  }
})
</script>
