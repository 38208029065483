/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios from '@/vendor/axios'

class API {
  static baseUrl: string = process.env.NODE_ENV === 'development'
    ? '/apiUrl/moc/api/info'
    : process.env.VUE_APP_API_URL + '/api/info'

  static Category (): Promise<any> {
    return axios.get(this.baseUrl + '/getICultureInfoCategoryList')
  }

  static FieldArena (): Promise<any> {
    return axios.get(this.baseUrl + '/getICultureInfoFieldArenaList')
  }

  static RegionArena (): Promise<any> {
    return axios.get(this.baseUrl + '/getICultureInfoRegionArenaList')
  }

  // [ITRI] Category List
  static ItriCategory (): Promise<any> {
    return axios.get(this.baseUrl + '/getItriCategoryList')
  }

  // [ITRI] Field & Arena List
  static ItriFieldArena (): Promise<any> {
    return axios.get(this.baseUrl + '/getItriFieldArenaList')
  }

  // [ITRI] Region & Arena List
  static ItriRegionArena (): Promise<any> {
    return axios.get(this.baseUrl + '/getItriRegionArenaList')
  }

  static RoleList (): Promise<any> {
    return axios.get(this.baseUrl + '/getRoleList')
  }
}

export default API
