// axios
import axios from './axios'

// moment
import moment from 'moment'

// numeral
import numeral from 'numeral'

// lodash
import lodash from 'lodash'

window.axios = axios
moment.locale('zh-tw')
window.moment = moment
window.numeral = numeral
window._ = lodash
